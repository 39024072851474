export default {
  data() {
    return {
      purchaseTypes: { 1: 'ՄԱ', 2: 'ՄԱ*', 3: 'ՀՄԱ', 4: 'ԲՄ', 5: 'ՀԲՄ', 6: 'ԳՀ', 7: 'ԷԱՃ' },
      purchaseTypesForCompetetive: { 3: 'ՀՄԱ', 4: 'ԲՄ', 5: 'ՀԲՄ', 6: 'ԳՀ' },
      purchaseTypesForOnePerson: { 1: 'ՄԱ', 2: 'ՄԱ*' },
      purchaseTypesForItender: { 1: 'ՓԳ', 2: 'ԲԱ', 3: 'ԼԱ' },
      purchaseTypesForItenderLongNames: { 1: 'Փակ գներով', 2: 'Բաց աճուրդ', 3: 'Լավագույն առաջարկ' },
      purchaseTypesLongNames: {
        hy: {
          1: 'մեկ անձից գնում', 2: 'մեկ անձից գնում*', 3: 'հրատապության հիմքով պայմանավորված մեկ անձից գնում', 4: 'բաց մրցույթ', 5: 'հրատապ բաց մրցույթ', 6: 'գնանշման հարցում', 7: 'ԷԱՃ'
        },
        ru: {
          1: 'закупка у одного лица', 2: 'закупка у одного лица*', 3: 'закупка у одного лица, обусловленная безотлагательностью', 4: 'открытый конкурс', 5: 'открытый конкурс, обусловленная безотлагательностью', 6: 'запрос котировок', 7: 'электронный аукцион'
        }
      },
      purchaseTypesLongNamesCased: {
        hy: {
          1: 'մեկ անձից գնման', 2: 'մեկ անձից գնման*', 3: 'հրատապության հիմքով պայմանավորված մեկ անձից գնման', 4: 'բաց մրցույթի', 5: 'հրատապ բաց մրցույթի', 6: 'գնանշման հարցման', 7: 'էլեկտրոնային  աճուրդի'
        },
        ru: {
          1: 'закупке у одного лица', 2: 'закупке у одного лица*', 3: 'закупке у одного лица, обусловленная безотлагательностью', 4: 'открытом конкурсе', 5: 'открытом конкурсе, обусловленная безотлагательностью', 6: 'запросе котировок', 7: 'электронном аукционе'
        }
      },
      purchaseTypesLongNamesCased2: {
        hy: {
          1: 'մեկ անձից գնմանը', 2: 'մեկ անձից գնմանը*', 3: 'հրատապության հիմքով պայմանավորված մեկ անձից գնմանը', 4: 'բաց մրցույթին', 5: 'հրատապ բաց մրցույթին', 6: 'գնանշման հարցմանը', 7: 'էլեկտրոնային  աճուրդին'
        },
        ru: {
          1: 'закупки у одного лица', 2: 'закупки у одного лица*', 3: 'закупки у одного лица, обусловленная безотлагательностью', 4: 'открытого конкурса', 5: 'открытого конкурса, обусловленная безотлагательностью', 6: 'запроса котировок', 7: 'электронного аукциона'
        }
      }
    }
  },
}
